import { Flex, Spinner, Heading, Grid, GridItem, Text, Center, Badge, useDisclosure, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { ICompanyDetails } from "../../../interfaces/company-interface";
import { getFranchiseDetails, listBanks } from "../../../services/api";
import { FaRegUserCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import { FaFileContract } from "react-icons/fa";
import { AiFillBank } from "react-icons/ai";
import { IoIosBusiness } from "react-icons/io";
import { IBank } from "../../../interfaces/banckaccount-interface";
import { IDetailsFranchise } from "../../../interfaces/franchise-interface";
import { SelectEditCompanyPage } from "../../Company/Forms/Edit/SelectEditPage/Index";
import { HeaderFranchise } from "../Components/Header";
import { SelectEditFranchisePage } from "../Forms/Edit/SelectEditPage/Index";




export const FranchiseDetails = () => {
    const [franchise, setFranchise] = useState<IDetailsFranchise>({} as IDetailsFranchise);
    const [loading, setLoading] = useState<boolean>(true);
    const [bankName, setBankName] = useState<string>('' as string);
    const [banks, setBanks] = useState<IBank[]>([])
    const [isLoadingBanckName, setIsLoadingBankName] = useState<boolean>(true);
    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleGetDayOfWeek = (day: number) => {
        switch (day) {
            // case 0:
            //     return 'Domingo'
            case 1:
                return 'Segunda-feira'
            case 2:
                return 'Terça-feira'
            case 3:
                return 'Quarta-feira'
            case 4:
                return 'Quinta-feira'
            case 5:
                return 'Sexta-feira'
            // case 6:
            //     return 'Sábado'
            default:
                return 'Dia não encontrado'
        }
    }


    const handleGetBanks = (companyData: IDetailsFranchise) => {
        setIsLoadingBankName(true)
        listBanks().then((response) => {
            if (response.data.data) {
                setBanks(response.data.data);
                const bankName = response.data.data.find((bank) => bank.code === companyData.bankAccounts[0].bank_code)
                if (bankName) {
                    setBankName(bankName.long_name)
                }
            }
            setIsLoadingBankName(false)
        }).catch((error) => {
            console.log(error)
            setIsLoadingBankName(false)
        }).finally(() => {
            setIsLoadingBankName(false)
        })
    }

    let { id } = useParams();

    function init() {
        setLoading(true);
        getFranchiseDetails(id!).then((response) => {
            setFranchise(response.data.data);
            setLoading(false);
            handleGetBanks(response.data.data);
        })
            .catch((error) => {
                setLoading(false);
            });
    }

    useEffect(() => {
        init();
    }, [])

    if (loading) {
        return (
            <Flex justify="center" align="center" height="100vh">
                <Spinner size="xl" />
            </Flex>
        );
    }

    if (!franchise) {
        return <Text>Não foi possivel encontrar informações da franquia</Text>;
    }

    return (
        <>
            <HeaderFranchise
                franchiseName={franchise.company_name}
                franchiseId={Number(franchise.id)}
                onOpenModalSelect={onOpen}
            />
            <Grid minH="99vh" minW="99vw" p={3} padding={0}>
                <Flex>
                    <Sidebar />
                    <div className="content">
                        {
                            !loading ?
                                <Grid
                                    templateColumns='repeat(1, 1fr)'
                                    gap={6}
                                >
                                    <Grid
                                        templateColumns='repeat(2, 1fr)'
                                        gap={6}
                                    >
                                        <GridItem
                                            style={{ border: '1px solid #e2e8f0', padding: '20px', borderRadius: '6px' }}
                                        >
                                            <Flex
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                            >
                                                <Heading as="h2" size="lg" mt={6} mb={4}>
                                                    <Flex
                                                        justifyItems={'center'}
                                                        alignItems={'center'}
                                                    >
                                                        <IoIosBusiness
                                                            size={30}
                                                        />
                                                        {franchise.company_name}
                                                    </Flex>
                                                </Heading>
                                            </Flex>
                                            <GridItem>
                                                <Text>
                                                    <strong>Email:</strong> {franchise.email}
                                                </Text>
                                                <Text>
                                                    <strong>{franchise.type === 'individual' ? 'CPF' : 'CNPJ'}</strong> {franchise.document_number}
                                                </Text>
                                                <Text>
                                                    <strong>Tipo:</strong> {franchise.type === 'individual' ? 'Pessoa Fisica' : 'Pessoa Juridica'}
                                                </Text>
                                                {
                                                    franchise.type === 'individual' && <Text>
                                                        <strong>Profissão:</strong>  {franchise.professional_occupation}
                                                    </Text>
                                                }
                                                {
                                                    franchise.type === 'individual' && <Text>
                                                        <strong>Data de nascimento:</strong>  {franchise.birthdate}
                                                    </Text>
                                                }
                                                <Text>
                                                    <strong>Telefone:</strong> ({franchise.ddd}) {franchise.number_phone}
                                                </Text>
                                                <Text>
                                                    <strong>Tipo de telefone:</strong> {franchise.type_phone}
                                                </Text>
                                                <Text>
                                                    <strong>Faturamento Anual:</strong> R${franchise.annual_revenue}
                                                </Text>
                                                <Text>
                                                    <strong>Nome Fantasia:</strong> {franchise.trading_name}
                                                </Text>
                                            </GridItem>
                                            <GridItem>
                                                <Text>
                                                    <strong>Endereço:</strong> {`
                                                    ${franchise.address?.city} - ${franchise.address?.state},
                                                    ${franchise.address?.street}, ${franchise.address?.number},
                                                    ${franchise.address?.complementary},
                                                    `}
                                                </Text>
                                            </GridItem>
                                        </GridItem>
                                        <GridItem
                                            style={{ border: '1px solid #e2e8f0', padding: '20px', borderRadius: '6px' }}
                                        >
                                            <Flex
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                            >
                                                <Heading as="h2" size="lg" mt={6} mb={4}>
                                                    <Flex
                                                        justifyItems={'center'}
                                                        alignItems={'center'}
                                                    >
                                                        <FaRegUserCircle />
                                                        Administrador
                                                    </Flex>
                                                </Heading>
                                            </Flex>
                                            <GridItem>
                                                <Text>
                                                    <strong>Nome:</strong> {franchise.admins[0].name}
                                                </Text>
                                                <Text>
                                                    <strong>Email:</strong> {franchise.admins[0].email}
                                                </Text>
                                                <Text>
                                                    <strong>CPF:</strong> {franchise.admins[0].document_number}
                                                </Text>
                                                <Text>
                                                    <Text>
                                                        <strong>Data de Nascimento:</strong>   {franchise.admins[0].birthdate}
                                                    </Text>
                                                    <strong>Telfone:</strong> ({franchise.ddd}) {franchise.admins[0].phone_number}
                                                </Text>
                                                <Text>
                                                    <strong>Tipo de telefone:</strong> {franchise.admins[0].phone_type}
                                                </Text>
                                                <Text>
                                                    <strong>Profissão:</strong> {franchise.admins[0].professional_occupation}
                                                </Text>
                                                <Text>
                                                    <strong>Renda mensal:</strong> {franchise.admins[0].monthly_income}
                                                </Text>
                                                {/* <Text>
                                                    <strong>Endereço</strong> {
                                                        `${franchise.admins[0].user.address[0].city} - ${franchise.admins[0].user.address[0].state},
                                                    ${franchise.admins[0].user.address[0].street}, ${franchise.admins[0].user.address[0].number},
                                                    ${franchise.admins[0].user.address[0].complementary}`
                                                    }
                                                </Text> */}
                                            </GridItem>
                                        </GridItem>
                                    </Grid>
                                    <Grid
                                        templateColumns='repeat(2, 1fr)'
                                        gap={6}
                                    >
                                        <GridItem
                                            style={{ border: '1px solid #e2e8f0', padding: '20px', borderRadius: '6px' }}
                                        >
                                            <Flex
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                            >
                                                <Heading as="h2" size="lg" mt={6} mb={4}>
                                                    <Flex
                                                        justifyItems={'center'}
                                                        alignItems={'center'}
                                                    >
                                                        <AiFillBank />
                                                        Conta Bancaria
                                                    </Flex>
                                                </Heading>
                                            </Flex>
                                            <GridItem>
                                                <Text>
                                                    <Flex>
                                                        <strong
                                                            style={{ marginRight: '5px' }}
                                                        >{'Banco: '}</strong>  {
                                                            isLoadingBanckName ? <Spinner
                                                                colorScheme="blue"
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                }}
                                                            />
                                                                :
                                                                ` ${bankName}`
                                                        }
                                                    </Flex>
                                                </Text>
                                                <Text>
                                                    <strong>Nome:</strong> {franchise.bankAccounts[0].holder_name}
                                                </Text>
                                                <Text>
                                                    <strong>CPF:</strong> {franchise.bankAccounts[0].holder_document}
                                                </Text>
                                                <Text>
                                                    <strong>Tipo:</strong> {franchise.bankAccounts[0].account_type === 'checking' ? 'Conta Corrente' : 'Conta Poupança'}
                                                </Text>
                                                <Text>
                                                    <strong>Número da conta:</strong> {franchise.bankAccounts[0].account_number}
                                                </Text>
                                                <Text>
                                                    <strong>Dígito da conta:</strong> {franchise.bankAccounts[0].account_check_digit}
                                                </Text>
                                                <Text>
                                                    <strong>Número da agência:</strong> {franchise.bankAccounts[0].branch_number}
                                                </Text>
                                                <Text>
                                                    <strong>Dígito da agência:</strong> {franchise.bankAccounts[0].branch_check_digit}
                                                </Text>
                                                <Text>
                                                    <Flex>
                                                        <strong style={{ marginRight: '5px' }}>{"Transferencia:"}</strong> {
                                                            franchise.bankAccounts[0].transfer_enabled ? <Badge
                                                                colorScheme="green"
                                                                height={8}
                                                                width={16}
                                                                style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                                                                borderRadius={6}
                                                            >
                                                                Ativo
                                                            </Badge>
                                                                :
                                                                <Badge
                                                                    colorScheme="red"
                                                                    height={8}
                                                                    width={16}
                                                                    style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                                                                    borderRadius={6}
                                                                >
                                                                    Inativo
                                                                </Badge>
                                                        }
                                                    </Flex>
                                                </Text>
                                                <Text>
                                                    <strong>{'Intervalo de transferencia: '}</strong>{franchise.bankAccounts[0].transfer_interval === 'daily' ? 'Diario' : franchise.bankAccounts[0].transfer_interval === 'weekly' ? 'Semanal' : franchise.bankAccounts[0].transfer_interval === 'monthly' ? 'Mensal' : 'Anual'}
                                                </Text>
                                                {
                                                    franchise.bankAccounts[0].transfer_interval === 'weekly' && <Text>
                                                        <strong>{"Dia da semana: "}</strong>
                                                        {
                                                            handleGetDayOfWeek(franchise.bankAccounts[0].transfer_day)
                                                        }
                                                    </Text>
                                                }
                                                {
                                                    franchise.bankAccounts[0].transfer_interval === 'monthly' && <Text>
                                                        <strong>Dia do mês:</strong> {franchise.bankAccounts[0].transfer_day}
                                                    </Text>
                                                }
                                            </GridItem>
                                        </GridItem>
                                        {/* <GridItem
                                        style={{ border: '1px solid #e2e8f0', padding: '20px', borderRadius: '6px' }}
                                    >
                                        <Flex
                                            justifyContent={'center'}
                                        >
                                            <Heading as="h2" size="lg" mt={6} mb={4} >
                                                <Flex
                                                    justifyItems={'center'}
                                                    alignItems={'center'}
                                                >
                                                    <FaFileContract
                                                        size={26}
                                                    />
                                                    Contrato
                                                </Flex>
                                            </Heading>
                                        </Flex>
                                         <GridItem>
                                            <Text>
                                                <Flex justifyItems={'center'} alignItems={'center'}>
                                                    <strong style={{ marginRight: '5px' }}>
                                                        {"Contrato:"}
                                                    </strong> {franchise.contract?.is_enable ? <Badge
                                                        colorScheme="green"
                                                        height={8}
                                                        width={16}
                                                        style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                                                        borderRadius={6}
                                                    >
                                                        Ativo
                                                    </Badge> :
                                                        <Badge
                                                            colorScheme="red"
                                                            height={8}
                                                            width={16}
                                                            style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                                                            borderRadius={6}
                                                        >
                                                            Inativo
                                                        </Badge>}
                                                </Flex>
                                            </Text>
                                            <Text>
                                                <strong>Valor:</strong> {franchise.contract?.company_fee_percent}%
                                            </Text>
                                            <Text>
                                                <strong>Inicio:</strong> {new Date(franchise.contract?.created_at).getDate()}/{new Date(franchise.contract?.created_at).getMonth() + 1}/{new Date(franchise.contract?.created_at).getFullYear()}
                                            </Text>
                                            <Text>
                                                <strong>
                                                    {' Valor final para o cliente: '}
                                                </strong>
                                                {franchise.contract.final_customer_fee_percent}
                                            </Text>
                                        </GridItem> 
                                    </GridItem> */}
                                    </Grid>
                                </Grid>
                                :
                                <Center>
                                    <Spinner size="xl"
                                        thickness="4px"
                                        speed="0.65s"
                                        emptyColor="gray.200"
                                        color="blue.500"
                                    />
                                </Center>
                        }
                    </div>
                </Flex>
            </Grid >
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader><ModalCloseButton /></ModalHeader>
                    <ModalBody>
                        <SelectEditFranchisePage
                            franchiseName={franchise.company_name}
                            franchiseId={Number(franchise.id)}
                            adminId={Number(franchise.admins[0].id)}
                            bankAccountId={Number(franchise.bankAccounts[0].id)}
                        // contractId={Number(company.contract?.id)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='red' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    );
};
