import { HamburgerIcon, AddIcon, ExternalLinkIcon, RepeatIcon, EditIcon } from "@chakra-ui/icons";
import { Box, Button, IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { deleteFranchiseById } from "../../../../services/api";
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

interface FranchiseCompanyProps {
    franchiseName: string;
    franchiseId: number;
    onOpenModalSelect: () => void;
}
export const HeaderFranchise = ({ franchiseName, franchiseId, onOpenModalSelect }: FranchiseCompanyProps) => {

    const navigate = useNavigate();
    function handleNavigate(path: string) {
        navigate(path)
    }

    async function deleteFranchise(){
        try {
            const {data} = await deleteFranchiseById(franchiseId)

            if (data.statusCode === 201 && !data.error) {
                toast.success(data.message)
                setTimeout(() => {
                    navigate('/franchise')
                }, 2000)
            }

        } catch (error) {
            toast.error('Erro ao deletar franquia!')
        }
    }

    return (
        <Box
            as="header"
            w="100%"
            h="50"
            bg="gray.800"
            color="white"
            backgroundColor={"#1D1D1D"}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    paddingLeft: '32px'
                }}
            >
                <span
                    style={{
                        fontStyle: "italic",
                    }}
                >
                    Onde é Hoje?
                </span>
            </div>

            <div
                style={{
                    width: '100%',
                }}
            >
                <span
                    style={{
                        marginLeft: 8,
                        fontWeight: 'bolder',
                        fontSize: 20,
                    }}
                >
                    {franchiseName}
                </span>
            </div>

            <div
                style={{
                    width: '30%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    paddingRight: '32px'
                }}
            >
                <Menu
                >
                    <MenuButton
                        colorScheme='white'
                        as={IconButton}
                        aria-label='Options'
                        icon={<HamburgerIcon />}
                    />
                    <MenuList
                        color='black'
                    >
                        <MenuItem
                            icon={<FaPencilAlt />}
                            onClick={onOpenModalSelect}
                        >
                            Editar dados da franquia
                        </MenuItem>
                        
                        <MenuItem
                         color={'red'}
                            icon={<FaTrashAlt />}
                            onClick={() => deleteFranchise()}
                        >
                            Excluir franquia
                        </MenuItem>
                    </MenuList>
                </Menu>
            </div>
            <ToastContainer />
        </Box >
    );
}